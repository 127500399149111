import ApiService from "../../api/api.service"
import JwtService from "../../common/jwt.service"
import { SET_AUTH, SET_ERROR, RESET_AUTH, CLEAR_ERRORS, UPDATE_USER_INFO } from "../mutations.type"

import { LOGIN, ADMIN_LOGIN, LOGOUT, ADMINLOGOUT,CHANGE_PASSWORD, SET_PROFILE_DATA,OTP_LOGIN, CHECK_AUTH } from "../actions.type"

const getDefaultState = () => {
    return {
        token: null,
        userId: null,
        userType: null,
        otpUser:null,
        loggedInPanel: JwtService.getTLoggedInPanel(),
        isAuthenticated: !!JwtService.getToken() 
    }
};

const state = getDefaultState();

const getters = {
    currentUser(state) {
        return state.userId;
    },
    getCurrentUserName(state) {
        return state.userId ? state.userId.first_name : null;
    },
    isAuthenticated(state) {
        return state.isAuthenticated;
    },
    getUserType(state) {
        return state.userType;
    },
    getUserLoggedInPanel(state) {
        return state.loggedInPanel;
    },
    getOtpVerificationUser(state) {
        return state.otpUser;
    }
};

const mutations = {
    [SET_AUTH](state, data) {
        state.isAuthenticated = true;
        state.userId = data.userId;
        state.token = data.token;
        state.errors = getDefaultState().errors;
        state.userType = data.userType;
        state.loggedInPanel = data.loggedInPanel;
        JwtService.setTLoggedInPanel(data.loggedInPanel);
        JwtService.setToken(data.token);
        JwtService.setUserId(data.userId.id);
        JwtService.setUserType(data.userId.user_type_id);

    },
    [SET_PROFILE_DATA](state, data) {
        state.userId = data.resultData.data;
    },
    [RESET_AUTH](state) {
        JwtService.unsetToken();
        Object.assign(state, getDefaultState());
    },
    [UPDATE_USER_INFO](state, data) {
        state.userId = data.userId;
    },
    [SET_ERROR](state, data) {
        state.otpUser = data.user;
    }
    
};

const actions = {
    [ADMIN_LOGIN](context, credentials) {
        return new Promise((resolve, reject) => {
                ApiService.post(credentials.api, credentials.data)
                    .then(({ data }) => {
                        context.commit(CLEAR_ERRORS);
                        if (!Boolean(data.success)) {
                            context.commit(
                                SET_ERROR, { message: data.message }
                            );
                            reject(data);
                        } else {
                            context.commit(
                                SET_AUTH, { userId: data.user, token: data.token, userType: data.user.user_type_id, loggedInPanel: 'admin' }
                            );
                            resolve(data);
                        }
                    })
                    .catch(({ response }) => {
                        console.log("Something went wrong");
                    });
            })
            .catch(response => {
                console.log("Something went wrong");
            });
    },
    [LOGIN](context, credentials) {
        return new Promise((resolve, reject) => {
                ApiService.post(credentials.api, credentials.data)
                    .then(({ data }) => {
                        context.commit(CLEAR_ERRORS);
                        if (!Boolean(data.success)) {
                            context.commit(
                                SET_ERROR, { message: data.message, user:data.user }
                            );
                            reject(data);
                        } else {
                            context.commit(
                                SET_AUTH, { userId: data.user, token: data.token/*, userType: data.user.user_type_id, loggedInPanel: 'company'*/ }
                            );
                            resolve(data);
                        }
                    })
                    .catch(({ response }) => {
                        console.log("Something went wrong");
                    });
            })
            .catch(response => {
                console.log("Something went wrong");
            });
    },

    [LOGOUT](context,credentials) {
        return new Promise((resolve, reject) => {
           ApiService.setHeader();
           ApiService.get(credentials.api)
                .then(({ data }) => {
                    localStorage.setItem('routeCFCheck','no');
                    context.commit(CLEAR_ERRORS);
                    context.commit(RESET_AUTH);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(
                        SET_ERROR, { message: response.data.error }
                    );
                    reject(response);
                });
        });
    },
    [ADMINLOGOUT](context,credentials) {
        return new Promise((resolve, reject) => {
           ApiService.setHeader();
           ApiService.get(credentials.api)
                .then(({ data }) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(RESET_AUTH);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(
                        SET_ERROR, { message: response.data.error }
                    );
                    reject(response);
                });
        });
    },
    [CHECK_AUTH](context) {
        if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.get("api/token-validate")
                .then(({ data }) => {
                    if (!Boolean(data.success)) {
                        context.commit(RESET_AUTH);
                        reject(data);
                        window.location.href = process.env.MIX_APP_URL;
                    }
                    resolve(data);
                })
                .catch(({ response }) => {
                    console.log("Something went wrong");
                });
        } else {
            context.commit(RESET_AUTH);
        }
    },
    [OTP_LOGIN](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.post(credentials.api, credentials.data)
                .then(({ data }) => {
                    context.commit(CLEAR_ERRORS);
                    if (!Boolean(data.success)) {
                        context.commit(
                            SET_ERROR, { message: data.message, user:data.user }
                        );
                        reject(data);
                    } else {
                        if(data.user_type == 5 )
                        {
                            context.commit(
                                SET_RESULT_DATA, { resultData: data }
                            );
                        }
                        else
                        {
                            context.commit(

                                SET_AUTH, { userId: data.user, token: data.token, userType: data.user.user_type_id, loggedInPanel: 'company' }
                            );

                        }
                        resolve(data);
                    }
                })
                .catch(({ response }) => {
                    console.log("Something went wrong1");
                });
        })
        .catch(response => {
            console.log("Something went wrong2");
        });
    },

    [CHANGE_PASSWORD](context, credentials) {
        
        return new Promise((resolve, reject) => {
                ApiService.post(credentials.api, credentials.data)
                    .then(({ data }) => {
                        context.commit(CLEAR_ERRORS);
                        if (!Boolean(data.success)) {
                            context.commit(
                                SET_ERROR, { message: data.message, user:data.user }
                            );
                            reject(data);
                        } else {
                            context.commit(
                                SET_AUTH, { userId: data.user, token: data.token}
                            );
                            resolve(data);
                        }
                    })
                    .catch(({ response }) => {
                        console.log("Something went wrong");
                    });
            })
            .catch(response => {
                console.log("Something went wrong");
            });
    },
    [SET_PROFILE_DATA](context, credentials) {
        return new Promise((resolve, reject) => {
                ApiService.post(credentials.api, credentials.data)
                    .then(({ data }) => {
                        context.commit(CLEAR_ERRORS);
                        if (!Boolean(data.success)) {
                            context.commit(
                                SET_ERROR, { message: data.message, user:data.user }
                            );
                            reject(data);
                        } else {
                            context.commit(
                                UPDATE_USER_INFO, { userId: data.user }
                            );
                            resolve(data);
                        }
                    })
                    .catch(({ response }) => {
                        console.log("Something went wrong");
                    });
            })
            .catch(response => {
                console.log("Something went wrong");
            });
    },
};

export default {
    getters,
    actions,
    mutations,
    state
}
